import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';



import Slots from "../components/Slots";

import Pay from "../assets/cb.png";













const Stages = () => {







  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const postalCode = queryParams.get('postalCode');



  const [Department, setDepartment] = useState('');










  
  const [stages, setStages] = useState([]);



  useEffect(() => {
    // Fonction pour récupérer les stages en fonction du département
    const fetchStages = async () => {
      try {
        // Vérifie si un département est spécifié dans l'URL
        if (postalCode) {
          const response = await fetch(`https://fourpoints-backend-render.onrender.com/api/sauces?postalCode=${postalCode}`);
          const data = await response.json();
          setStages(data);
        } else {
          // Si aucun département n'est spécifié, récupère tous les stages
          const response = await fetch(`https://fourpoints-backend-render.onrender.com/api/sauces`);
          const data = await response.json();
          setStages(data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    // Appeler la fonction pour récupérer les stages
    fetchStages();

  }, [postalCode]);








  return (
    <div className="stages">
 
 




      <div className="search-bar searchbar--stages">
        
        <select onChange={(e) => setDepartment(e.target.value)}>
          <option value="">Choisissez un lieu ou un département</option>
          <option value="Montreuil">Montreuil (93100)</option>
          <option value="Gennevilliers">Gennevilliers (92230)</option>
          <option value="Pantin">Pantin (93500)</option>
          <option value="Epinay Sur Seine">Epinay Sur Seine (93800)</option>
          <option value="Pierrefitte Sur Seine">Pierrefitte Sur Seine (93380)</option>
        </select>
        
        
        
        <Link to={`/stages?postalCode=${Department}`}>
          <button type="button">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </Link>
      </div>




      <div className="banner__line banner__line--stages">
        <div className="banner__line__content banner__line__content--stages">       
        <p className="line-text">Stage volontaire de récupération de points, pour permis probatoire, composition pénale, peine complémentaire.</p>
        </div>
      </div>













      <div className="stages__container">
        <p className="stages__container__route"> Accueil / Tous les stages de récupération de points en France * / Ile de France / {postalCode}</p>
        
        <h1>Stages de récupération de points {postalCode ? `à ${postalCode}` : 'Île-de-France'}</h1>
        <p className="stages__container__text">Retrouvez tous les stages de récupération de points {postalCode && `à ${postalCode}`}. En effectuant un stage, vous pouvez récupérer jusqu'à 4 points sur votre permis de conduire.</p>
       

          <Slots stages={stages} />


        <div className="stages__container__info">
          <div className="stages__container__info__contact">
          <p className="service">Besoin d'aide ?</p>
            <p className="phone-number"><i class="fas fa-phone"></i> +33 7 67 28 68 47</p>
            <p className="service">Conseils et inscription</p>
            <p>- du <span>Lundi</span> au <span>Vendredi</span> de 9h à 18h -</p>
          </div>
          <div className="stages__container__info__pay">
            <p>---- Plusieurs moyens de paiement ----</p>
            <p className="pay-text"><i class="fa-solid fa-lock"></i> Paiement CB sécurisé</p>
            <img src={Pay} alt="pay-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stages;