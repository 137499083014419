import React from 'react';

import { useState } from "react";




import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';










const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);





  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
  
    setIsProcessing(true);
  
    try {

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `https://plus4points.fr/confirmationreservation`,
        },
      });
  
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } 


    } catch (error) {
      // Handle errors during payment confirmation
      console.error("Error confirming payment:", error);
      setMessage("An unexpected error occurred.");
    }
  
    setIsProcessing(false);
  };












  return (
    <form className="stage-reservation__content__form__payment" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Chargement ... " : "Payer"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default Payment;