
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Map from "../assets/map.png";
import Logo from "../assets/logo.png";




import PayEmote from "../assets/payement emote.png";
import CallEmote from "../assets/appel-emote.png";
import ClientEmote from "../assets/emote-client-satisfait.png";
import StageEmote from "../assets/admin emote.png";









const Banner = () => {

  const [Department, setDepartment] = useState('');


  return (
    <div className="banner">


      <div className="banner__content">



        <div className="banner__content__infos">
          <img src={Logo} alt="Logo" />
          <p className="banner__content__infos__texte1">Tous les stages de récupération de points</p>
          <p className="banner__content__infos__texte2">Trouvez un stage et récupérez 4 points</p>

          <div className="search-bar">
      
            <select onChange={(e) => setDepartment(e.target.value)}>
              <option value="">Choisissez un lieu ou un département</option>
              <option value="Montreuil">Montreuil (93100)</option>
              <option value="Gennevilliers">Gennevilliers (92230)</option>
              <option value="Pantin">Pantin (93500)</option>
              <option value="Epinay Sur Seine">Epinay Sur Seine (93800)</option>
              <option value="Pierrefitte Sur Seine">Pierrefitte Sur Seine (93380)</option>
            </select>
            
            
            
            <Link to={`/stages?postalCode=${Department}`}>
              <button type="button">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </Link>
          </div>
        </div>



        <img src={Map} alt="map-iledefrance" className="Map" />

      </div>









      <div className="banner__line">
        <div className="banner__line__content">       
        <p className="line-text">Stage volontaire de récupération de points, pour permis probatoire, composition pénale, peine complémentaire.</p>
        </div>
      </div>






      <div className="banner__card">
        <div className="banner__card__infos">
          <img src={ClientEmote} alt="paiement" />
          <h3>98% Satisfaits</h3>

        </div>
        <div className="banner__card__infos">
        <img src={StageEmote} alt="paiement" />
          <h3>Stages agréés</h3>

        </div>
        <div className="banner__card__infos">
        <img src={CallEmote} alt="paiement" />
          <h3>Service d’experts</h3>

        </div>
        <div className="banner__card__infos">
        <img src={PayEmote} alt="paiement" />
          <h3>Paiement sécurisé</h3>

        </div>
      </div>




    </div>
  );
};

export default Banner;